/*
// jei reikia, galime perrasyti core komponentus, pvz.
init_tooltips = function(context) {
	$('[data-toggle="tooltip"]', context).tooltip({placement:'bottom'});
}
*/

$(function () {
    init_product_slider(document);
    init_economic_slider(document);
    init_related_slider(document);
    init_duration_slider(document);
    init_ecomm();
    init_likes();
    init_shares();
    init_secrets();
    init_dropdown_positioning();


    $('[data-scrollto]').on('click', function(e) {
        e.preventDefault();

        if (get_vw() > screen_sm_max) {
            $('html, body').animate({scrollTop: $($(this).data('scrollto')).offset().top - 20}, 'slow');
        } else {
            $('html, body').animate({scrollTop: $($(this).data('scrollto')).offset().top - 76}, 'slow');
        }
    });

    $(window).scroll(init_dropdown_positioning);

    $('.duks-list .single-tab .tab-header').click(function () {
        $(this).parent().find('.tab-content').slideToggle('fast', function () {
            $(this).parent().toggleClass('active')
        });
    });
});

function init_product_slider(context) {
    $('.product-slider-container', context).addClass('owl-container');
    $('.product-slider-container .product_listing', context).each(function () {
        // Add main classes
        $(this).addClass('owl-carousel');

        // Options
        $(this).owlCarousel({
            autoHeight: true,
            loop: false,
            autoplay: false,
            //autoplayTimeout: 5000,
            //autoplayHoverPause: true,
            nav: true,
            navText: [
                "<span class='icon icon-left-big'></span>",
                "<span class='icon icon-right-big'></span>",
            ],
            responsiveClass: true,
            // navContainer: '.owl-out-nav',dddddacdscvf zxxx
            slideBy: 'page',
            dots: false,
            responsive: {
                0: {
                    items: 2
                },
                750: {
                    items: 3
                },
                970: {
                    items: 4
                },
                1170: {
                    items: 5
                }
            }
        });
    });
}

function init_related_slider(context)
{
    if ($('#related-offers', context).length) {
        let related_offers = new Swiper('#related-offers .swiper-related', {
            slidesPerView: "1.3",
            spaceBetween: 16,
            scrollbar: {
                el: '#related-offers .swiper-scrollbar',
                draggable: true,
            },
            breakpoints: {
                550: {
                    slidesPerView: '1.8'
                },
                768: {
                    slidesPerView: '2.3'
                },
                992: {
                    slidesPerView: '4'
                }
            }
        });
    }
}

function init_duration_slider(context)
{
    if ($('.custom-swiper-wrp', context).length) {
        $('.custom-swiper-wrp', context).each(function () {
            let id = $(this).data('id');
            let container = document.querySelector('.custom-swiper-wrp[data-id="'+ id +'"]');
            let item = container.querySelector(".custom-swiper-item");
            let itemWidth = item.offsetWidth;

            container.parentNode.querySelector(".swiper-button-prev").addEventListener("click", function() {
                container.scrollBy({ left: -itemWidth, behavior: "smooth" });
            });
            container.parentNode.querySelector(".swiper-button-next").addEventListener("click", function() {
                container.scrollBy({ left: itemWidth, behavior: "smooth" });
            });

            container.addEventListener('scroll', (event) => {
                if (container.scrollLeft + container.clientWidth >= container.scrollWidth) {
                    container.parentNode.querySelector('.swiper-button-next').classList.add('swiper-button-disabled');
                } else if (container.scrollLeft == 0) {
                    container.parentNode.querySelector('.swiper-button-prev').classList.add('swiper-button-disabled');
                } else {
                    container.parentNode.querySelectorAll('.swiper-button').forEach(function(nav) {
                        nav.classList.remove('swiper-button-disabled');
                    });
                }
            });

            update_nav(container);
            window.addEventListener('resize', (event) => {
                update_nav(container);
            });

            function update_nav(element) {
                var display = 'none';

                if (element.clientWidth < element.scrollWidth) {
                    display = 'flex';
                }
                element.parentNode.querySelectorAll('.swiper-button').forEach(function(btn) {
                    btn.style.display = display;
                    btn.classList.remove('swiper-button-disabled')
                });

                element.dispatchEvent(new Event('scroll'));
            }
        });
    }
}

function init_economic_slider(context)
{
    if ($('#economic-offers', context).length) {
        let economic_offers = new Swiper('#economic-offers .swiper-economic', {
            slidesPerView: "1.3",
            spaceBetween: 16,
            scrollbar: {
                el: '#economic-offers .swiper-scrollbar',
                draggable: true,
            },
            breakpoints: {
                550: {
                    slidesPerView: '1.8'
                },
                768: {
                    slidesPerView: '2.3'
                },
                992: {
                    slidesPerView: '4'
                }
            }
        });
    }
}

function init_likes() {
    $('.like-act').each(function () {
        var exists = localStorage.getItem('fav-' + $(this).data('id'));
        if (exists === '1') {
            $(this).addClass('active');
            $(this).find('span').html($(this).data('likes') + 1);
        }
    });
    $('.like-act').click(function (e) {
        e.preventDefault();
        var exists = localStorage.getItem('fav-' + $(this).data('id'));
        if (exists === '1') {
            localStorage.removeItem('fav-' + $(this).data('id'));
            $(this).removeClass('active');
            $(this).find('span').html($(this).data('likes'));
        } else {
            localStorage.setItem('fav-' + $(this).data('id'), '1');
            $(this).addClass('active');
            $(this).find('span').html($(this).data('likes') + 1);
        }
    });
}

function init_shares() {
    $('.share-act').click(function (e) {
        e.preventDefault();
        if (navigator.share) {
            navigator.share({
                title: $(this).data('title'),
                url: $(this).data('url')
            });
        } else {
            if (!$("link[href='https://use.fontawesome.com/releases/v5.8.1/css/all.css']").length) {
                var head = document.getElementsByTagName('HEAD')[0];
                var link = document.createElement('link');
                link.rel = 'stylesheet';
                link.type = 'text/css';
                link.href = 'https://use.fontawesome.com/releases/v5.8.1/css/all.css';
                head.appendChild(link);
            }

            var title = encodeURIComponent($(this).data('title'));
            var url = encodeURIComponent($(this).data('url'));
            $('#shareModal').modal('show');
            $('#shareModal .modal-body a ').each(function () {
                if ($(this).data('share')) {
                    var href = $(this).data('share').replace('<title>', title).replace('<url>', url);
                    $(this).attr('href', href);
                }
            });
        }
    });
}

function init_secrets() {
    window.check_secrets = function (first_time = false) {
        $('.secret-offer').each(function () {
            var exists = localStorage.getItem('secret-' + $(this).data('id'));
            if (exists === '1') {
                $(this).find('.secret-overlay').removeClass('d-flex').hide();
                $(this).find('.secret-hidden').removeClass('d-none');
                $(this).removeClass('secret-offer');
            }
        });
        if (!first_time) {
            //trigger date
            if (window.search_air) {
                window.search_air._date_input_format();
                window.search_air.filterDateAndRange();
                window.search_air.month_checker();
            }
            //trigger duration
            if (window.search_duration_air) {
                window.search_duration_air._check_days();
                window.search_duration_air._check_increments();
                window.search_duration_air._input_format();
            }
            //trigger destination
            $('.destination-select .category-group > .single-category').each(function () {
                if ($(this).hasClass('active')) {
                    add_destination_bubble($(this).data('ftype'), $(this).data('fvalue'), $(this).data('fname'));

                    $(this).parent().find('.submenu_list > .single-category.active').each(function () {
                        window.customFilters.addValue($(this).data('ftype'), $(this).data('fvalue'));

                        if ($(this).data('fname') !== '') {
                            window.customFilters.addName($(this).data('ftype'), $(this).data('fname'));
                        }
                    });
                } else {
                    let active2 = false;

                    $(this).parent().find('.submenu_list > .single-category.active').each(function () {
                        active2 = true;
                        add_destination_bubble($(this).data('ftype'), $(this).data('fvalue'), $(this).data('fname'));

                        window.customFilters.addValue($(this).data('ftype'), $(this).data('fvalue'));
                        if ($(this).data('fname') !== '') {
                            window.customFilters.addName($(this).data('ftype'), $(this).data('fname'));
                        }
                    });

                    if (active2) {
                        $(this).parent().addClass('open');
                    }
                }
            });
            update_destination_btn_text();
        }
    }

    window.check_secrets(true);

    $(document).on('click', '.secret-offer', function (e) {
        e.preventDefault();
        localStorage.setItem('secret-' + $(this).data('id'), '1');
        var ele = $(this);
        $(this).find('.lock').removeClass('justify-content-center').addClass('justify-content-end pr-3')
        $(this).find('.lock').animate({
            width: $(this).find('.lock-background').width() + parseInt($(this).find('.lock-background').css('padding-right')) - parseInt($(this).find('.lock-background').css('padding-left'))
        }, 500, function() {
            ele.find('.secret-overlay').removeClass('d-flex').hide();
            ele.find('.secret-hidden').removeClass('d-none');
            ele.removeClass('secret-offer');
        });
    });
}

function init_ecomm() {
    window.sendPendingImpressions = function() {
        setTimeout(function() {
            if (window.pendingImpressions.length > 0) {
                var impressionsToSend = window.pendingImpressions;
                window.pendingImpressions = [];
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({ ecommerce: null });
                window.dataLayer.push({
                    'event': 'productImpressions',
                    'ecommerce': {
                        'impressions': impressionsToSend
                    }
                });
            }
            window.sendPendingImpressions();
        }, 1000);
    }
    window.sendPendingImpressions();
    $(document).on('click', '.single-offer', function(e) {
        var ele = $(this);

        // ga old
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
            'event': 'productClick',
            'ecommerce': {
                'detail': {
                    'products': [{
                        'name': ele.data('name'),
                        'id': ele.data('id'),
                        'price': ele.data('price')
                    }]
                }
            },
        });

        // ga4
        let ge_el = ele.parents('.product_element_wrapper');
        let promotion = ge_el.data('promotion');
        window.dataLayer.push({ ecommerce: null });
        if (promotion == true) {
            window.dataLayer.push({
                event: "select_promotion",
                ecommerce: {
                    creative_name: ge_el.data('direction'),
                    creative_slot: ge_el.data('url'),
                    promotion_id: ge_el.data('direction') + Math.ceil(ge_el.data('price')),
                    promotion_name: ge_el.data('direction') + ' ' + Math.ceil(ge_el.data('price')) + ' ' + ge_el.data('currency'),
                    items: [
                        {
                            item_id: ge_el.data('item-id'),
                            item_name: ge_el.data('name'),
                            discount: ge_el.data('item-discount'),
                            item_category: ge_el.data('category'),
                            item_variant: ge_el.data('item-variant'),
                            price: ge_el.data('price'),
                            quantity: 1
                        }
                    ]
                }
            });
        } else {
            window.dataLayer.push({
                event: "select_item",
                ecommerce: {
                    item_list_id: ge_el.data('list-id'),
                    item_list_name: ge_el.data('list-name'),
                    items: [
                        {
                            item_id: ge_el.data('item-id'),
                            item_name: ge_el.data('name'),
                            discount: ge_el.data('item-discount'),
                            item_category: ge_el.data('category'),
                            item_variant: ge_el.data('item-variant'),
                            price: ge_el.data('price'),
                            quantity: 1
                        }
                    ]
                }
            });
        }
    });
    $(document).on('submit', '.single-booking-form', function(e) {
        var ele = $(this);
        var btn_ele = $(this).find('.order-btn');
        btn_ele.attr('disabled','disabled').val(btn_ele.data('active-title'));

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
            'event': 'addToCart',
            'ecommerce': {
                'add': {
                    'products': [{
                        'name': window.productData.name,
                        'id': window.productData.id,
                        'price': ele.data('price'),
                        'destination': window.productData.destination,
                        'month': window.productData.month,
                        'quantity': 1
                    }]
                }
            },
        });
    });

    $(document).on('submit', '#cart_additional, #cart_additional_mob', function(e) {
        if (window.form_submitted) {
            return false;
        } else {
            window.form_submitted = true;
            var btn_ele = $('#cart_additional, #cart_additional_mob').find('.btn-step-additional');
            $('#cart_additional, #cart_additional_mob').find('.btn-step-additional').each(function(e) {
                $(this).attr('disabled','disabled').html(btn_ele.data('active-title'));
            });
            return true;
        }
    });

    $(document).on('submit', '#cart_confirm', function(e) {
        if (window.form_submitted) {
            return false;
        } else {
            window.form_submitted = true;
            var btn_ele = $('#cart_confirm').find('#desktop-pay-btn');
            $('#cart_confirm').find('#desktop-pay-btn, #mobile-pay-btn, #pay-reservation-btn').each(function(e) {
                if($(this).attr('id') == 'pay-reservation-btn') {
                    $(this).attr('disabled','disabled');
                } else {
                    $(this).attr('disabled','disabled');
                    if($('#reservation-status').val() == 0) {
                        $(this).val(btn_ele.data('active-title'))
                    }
                }
            });
            return true;
        }
    });

    $(document).on('click', '#pay-reservation-btn', function() {
        $('#reservation-status').val(1);
        $(this).text($(this).data('active-title'));
        $('#cart_confirm').find('#desktop-pay-btn, #mobile-pay-btn, #pay-reservation-btn').each(function(e) {
            $(this).attr('disabled','disabled');
        });

        $('#cart_confirm').submit();
    });
}

function init_dropdown_positioning() {
    let referenceEl = document.getElementById('departure-filter');
    let floatingEl = document.getElementById('departure-filter-dropdown');
    floating_dropdown(referenceEl, floatingEl);

    let referenceEl2 = document.querySelector('.destination-select-wrp');
    let floatingEl2 = document.querySelector('.destination-select');
    floating_dropdown(referenceEl2, floatingEl2);

    let referenceEl3 = document.querySelector('.search-body #air_date_from');
    let floatingEl3 = document.querySelector('.search-body .datepicker_air_div .datepicker_air');
    floating_dropdown(referenceEl3, floatingEl3);

    let referenceEl4 = document.querySelector('#departure-filter-wrp #departureFilterBtn');
    let floatingEl4 = document.querySelector('#departure-filter-wrp #departureFilter');
    floating_dropdown(referenceEl4, floatingEl4);
}

function floating_dropdown(reference, element) {
    if (reference && element) {
        FloatingUIDOM.computePosition(reference, element, {
            middleware: [FloatingUIDOM.autoPlacement({
                // 'right' and 'left' won't be chosen
                allowedPlacements: ['top', 'bottom'],
            })],
        }).then(({placement}) => {
            element.classList.remove('top', 'bottom');
            element.classList.add(placement);
        });
    }
}